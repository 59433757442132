import React, { useCallback, useEffect, useState } from 'react';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';

const USDollar = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

const convertSignal = (signal: string) => {
	switch (signal) {
		case 'new_low':
			return 'New Low';
		case 'new_high':
			return 'New High';
		case 'top_gain':
			return 'Top Gainers';
		case 'top_lose':
			return 'Top Losers';
		case 'top_52low':
			return '52 Week Low';
	}
	return '';
};

const convertTooltipSignal = (signal: string) => {
	switch (signal) {
		case 'new_low':
			return 'Stocks Hitting New 52-Week Low Today';
		case 'new_high':
			return 'Stocks Hitting New 52-Week High Today';
		case 'top_gain':
			return 'Stocks with the Highest Price Gains Today';
		case 'top_lose':
			return 'Stocks with the Highest Price Loss Today';
		case 'top_52low':
			return 'Stocks at 52-Week Low Today';
	}
	return '';
};

const getRandomNumber = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

const StockCardCarousel = ({ items, theme, title = '' }) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [maxIndex, setMaxIndex] = useState(items.length - 1);
	const [numberSlides, setSlidesPerView] = useState(4);
	const [loaded, setLoaded] = useState(false);

	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		slides: {
			perView: numberSlides,
			spacing: 15,
		},
		slideChanged(slider) {
			setCurrentSlide(slider.track.details.rel);
			setMaxIndex(slider.track.details.maxIdx);
		},
		created() {
			setLoaded(true);
		},
	});

	const updateSlidesPerView = useCallback(() => {
		const width = window.innerWidth;
		if (width >= 1800) {
			setSlidesPerView(6); // xl
		} else if (width >= 1400) {
			setSlidesPerView(5); // lg
		} else if (width >= 1024) {
			setSlidesPerView(4); // lg
		} else if (width >= 768) {
			setSlidesPerView(3); // md
		} else if (width >= 640) {
			setSlidesPerView(2); // sm
		} else {
			setSlidesPerView(1); // default
		}
	}, []);

	useEffect(() => {
		updateSlidesPerView();
		window.addEventListener('resize', updateSlidesPerView);
		return () => {
			window.removeEventListener('resize', updateSlidesPerView);
		};
	}, [updateSlidesPerView]);

	const Arrow = (props) => {
		const disabled = props.disabled ? ' arrow--disabled' : '';
		return (
			<svg
				onClick={props.onClick}
				fill={theme.value === 'dark' ? '#FFFFFF' : '#000000'}
				className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabled}`}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
			>
				{props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
				{!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
			</svg>
		);
	};

	return (
		<div className={'mt-2'}>
			{title.length > 0 && <h2 className={'text-center mb-1 mt-5 lg:text-xl text-sm'}>{title}</h2>}
			<div className={'block relative'}>
				<div ref={sliderRef} className="keen-slider max-w-fit cursor-pointer">
					{items.map((item, index) => (
						<div className={`keen-slider__slide number-slide${index}`} key={index + item.ticker}>
							<div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
								<div className="p-4 md:p-5 flex justify-between gap-x-3">
									<div>
										<p className="text-lg uppercase tracking-wide text-gray-500 dark:text-slate-100 font-bold">{item.ticker}</p>
										<div className="mt-1 flex items-center gap-x-2">
											<h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
												{USDollar.format(item.last_price.toFixed(2))}
											</h3>
											{item.change_from_previous_close >= 0 && (
												<span className="flex items-center gap-x-1 text-green-600">
													<svg
														className="inline-block size-5 self-center"
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													>
														<polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
														<polyline points="16 7 22 7 22 13" />
													</svg>
													<span className="inline-block text-lg">{item.change_from_previous_close.toFixed(2) + '%'}</span>
												</span>
											)}
											{item.change_from_previous_close < 0 && (
												<span className="flex items-center gap-x-1 text-red-600">
													<svg
														className="inline-block size-4 self-center"
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													>
														<polyline points="22 17 13.5 8.5 8.5 13.5 2 7" />
														<polyline points="16 17 22 17 22 11" />
													</svg>
													<span className="inline-block text-lg">{item.change_from_previous_close.toFixed(2) + '%'}</span>
												</span>
											)}
										</div>
										<div className="flex items-center gap-1">
											<span className="text-gray-800 dark:text-neutral-200">{convertSignal(item.signal)}</span>
											<div className="hs-tooltip">
												<div className="hs-tooltip-toggle">
													<svg
														className="size-3.5 text-gray-500 dark:text-neutral-500"
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														viewBox="0 0 16 16"
													>
														<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
														<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>
													</svg>
													<span
														className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700 hidden"
														role="tooltip"
														data-popper-placement="top"
													>
														{convertTooltipSignal(item.signal)}
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="shrink-0 flex justify-center items-center size-[46px] text-white rounded-full">
										{item.logo_url.length > 0 ? (
											item.logo_url.endsWith('.svg') ? (
												<img
													height={100}
													width={100}
													className="max-h-14 w-20 py-2"
													src={import.meta.env.PUBLIC_BUCKET_URL + item.logo_url}
													alt={'Logo ' + item.ticker}
												/>
											) : (
												<img
													height={100}
													width={100}
													className="max-h-14 py-2 w-20"
													src={import.meta.env.PUBLIC_BUCKET_URL + item.logo_url}
													alt={'Logo ' + item.ticker}
												/>
											)
										) : (
											<div className="h-auto w-20" />
										)}
									</div>
								</div>
								<a
									className="py-3 px-4 md:px-5 inline-flex justify-between items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 rounded-b-xl dark:border-neutral-800 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
									href={`/${item.ticker}/`}
								>
									View {item.ticker} Details
									<svg
										className="shrink-0 size-4"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path d="m9 18 6-6-6-6" />
									</svg>
								</a>
							</div>
						</div>
					))}
				</div>
				{loaded && instanceRef.current && (
					<>
						{currentSlide !== 0 && (
							<Arrow left onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()} disabled={currentSlide === 0} />
						)}
						{currentSlide !== maxIndex && (
							<Arrow onClick={(e) => e.stopPropagation() || instanceRef.current?.next()} disabled={currentSlide === maxIndex} />
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default StockCardCarousel;
